const potpisLottie = {
  v: "4.8.0",
  meta: { g: "LottieFiles AE ", a: "", k: "", d: "", tc: "" },
  fr: 30,
  ip: 0,
  op: 90,
  w: 240,
  h: 96,
  nm: "potpis goleš",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Layer 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [118.5, 47.5, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [5.141, -2.052],
                          [-5.038, 2.087],
                          [-7.547, 2.054],
                          [-0.364, -0.604],
                          [0.365, -0.348],
                          [3.933, -3.79],
                          [1.518, -1.567],
                          [0.052, -0.108],
                          [-0.141, -0.014],
                          [-0.489, -0.034],
                          [-0.292, -0.016],
                          [0.124, 0.207],
                          [-0.059, 0.03],
                          [-7.72, 2.179],
                          [-4.79, -0.111],
                          [-1.287, -1.017],
                          [-1.276, 0.299],
                          [-6.409, 1.07],
                          [-1.191, 0.065],
                          [-0.118, -0.79],
                          [0.65, -0.34],
                          [3.88, -1.765],
                          [3.585, -0.743],
                          [0.715, 2.17],
                          [1.647, -0.511],
                          [3.084, -0.857],
                          [4.936, -1.353],
                          [5.602, -1.171],
                          [5.314, -2.831],
                          [4.388, -2.193],
                          [6.578, -3.242],
                          [4.589, -2.284],
                          [3.89, -1.796],
                          [3.321, -0.643],
                          [1.045, 1.533],
                          [-0.873, 1.217],
                          [-4.83, 4.228],
                          [-1.026, 0.897],
                          [-0.035, 0.078],
                          [0.198, -0.048],
                          [3.817, -0.973],
                          [4.539, -1.18],
                          [2.105, -0.514],
                          [0.153, 0.43],
                          [-0.311, 0.145],
                          [-4.521, 2.007],
                          [-4.715, 1.964],
                          [-3.914, 1.593],
                          [-0.036, 0.043],
                          [0.095, 0.053],
                          [0.098, 0.661],
                          [-0.482, 0.254],
                          [-5.998, 2.626],
                          [-0.844, 0.904],
                          [-1.119, 0.546],
                          [-1.511, 0.818],
                          [-0.412, -0.54],
                          [0.346, -0.204],
                          [1.831, -0.896],
                          [2.048, -0.976],
                          [0.055, -0.106],
                          [-0.151, 0.046],
                          [-6.301, 2.362],
                          [-3.61, 1.184],
                          [-2.819, 0.852],
                          [-5.052, 1.57],
                          [-5.066, 1.545],
                          [-4.761, 1.398],
                          [-8.145, 2.354],
                          [-8.34, 2.136],
                          [-11.107, 2.514],
                          [-0.905, 0.257],
                          [-0.12, -0.456],
                          [0.402, -0.095],
                          [6.074, -1.423],
                          [6.426, -1.548],
                          [4.782, -1.322],
                          [6.747, -1.951],
                          [5.392, -1.613],
                          [6.88, -2.124],
                          [7.382, -2.273],
                        ],
                        o: [
                          [5.47, -0.253],
                          [7.148, -2.96],
                          [0.51, -0.139],
                          [0.375, 0.622],
                          [-3.953, 3.772],
                          [-1.575, 1.518],
                          [-0.082, 0.084],
                          [0.137, 0.045],
                          [0.487, 0.049],
                          [0.292, 0.021],
                          [-0.144, -0.198],
                          [-0.024, -0.04],
                          [7.077, -3.465],
                          [4.48, -1.264],
                          [1.487, 0.035],
                          [1.103, 0.873],
                          [6.299, -1.479],
                          [1.177, -0.196],
                          [0.562, -0.031],
                          [0.117, 0.788],
                          [-3.764, 1.973],
                          [-3.245, 1.477],
                          [-2.841, 0.589],
                          [-0.484, -1.468],
                          [-3.049, 0.945],
                          [-4.931, 1.369],
                          [-5.514, 1.511],
                          [-6.134, 1.282],
                          [-4.32, 2.301],
                          [-6.557, 3.278],
                          [-4.6, 2.266],
                          [-3.832, 1.908],
                          [-2.974, 1.373],
                          [-1.836, 0.356],
                          [-1.04, -1.526],
                          [3.689, -5.145],
                          [1.025, -0.898],
                          [0.061, -0.053],
                          [-0.212, -0.004],
                          [-3.832, 0.922],
                          [-4.545, 1.159],
                          [-2.095, 0.545],
                          [-0.389, 0.095],
                          [-0.141, -0.393],
                          [4.479, -2.086],
                          [4.661, -2.069],
                          [3.898, -1.623],
                          [0.047, -0.019],
                          [-0.105, -0.037],
                          [-0.416, -0.231],
                          [-0.104, -0.695],
                          [5.789, -3.066],
                          [2.221, -0.973],
                          [0.593, -0.221],
                          [1.597, -0.78],
                          [0.406, -0.22],
                          [0.446, 0.585],
                          [-2.035, 1.2],
                          [-2.154, 1.053],
                          [-0.1, 0.048],
                          [0.165, -0.007],
                          [6.444, -1.954],
                          [3.56, -1.334],
                          [2.763, -0.906],
                          [5.066, -1.53],
                          [5.056, -1.572],
                          [4.744, -1.447],
                          [8.134, -2.388],
                          [8.26, -2.388],
                          [11.027, -2.822],
                          [0.916, -0.207],
                          [0.485, -0.137],
                          [0.124, 0.475],
                          [-6.071, 1.435],
                          [-6.439, 1.508],
                          [-4.832, 1.164],
                          [-6.772, 1.872],
                          [-5.409, 1.564],
                          [-6.902, 2.066],
                          [-7.38, 2.279],
                          [-5.216, 1.608],
                        ],
                        v: [
                          [-31.626, -2.401],
                          [-15.957, -5.354],
                          [6.094, -12.955],
                          [7.613, -12.796],
                          [6.93, -11.519],
                          [-6.031, -1.383],
                          [-10.607, 3.301],
                          [-10.767, 3.622],
                          [-10.356, 3.753],
                          [-8.892, 3.866],
                          [-8.016, 3.915],
                          [-8.438, 3.317],
                          [-8.337, 3.115],
                          [13.52, -6.033],
                          [27.215, -8.717],
                          [31.342, -7.387],
                          [35.182, -7.891],
                          [54.113, -12.192],
                          [57.666, -12.665],
                          [59.313, -12.182],
                          [57.869, -10.655],
                          [46.508, -4.853],
                          [36.574, -0.778],
                          [31.615, -4.044],
                          [28.82, -5.389],
                          [19.549, -2.88],
                          [4.776, 1.28],
                          [-11.816, 5.648],
                          [-28.503, 12.588],
                          [-41.655, 19.186],
                          [-61.333, 29.009],
                          [-75.201, 35.692],
                          [-86.599, 41.577],
                          [-95.82, 45.169],
                          [-100.667, 43.898],
                          [-99.39, 39.764],
                          [-86.117, 26.093],
                          [-83.027, 23.412],
                          [-82.92, 23.182],
                          [-83.556, 23.177],
                          [-95.043, 25.973],
                          [-108.649, 29.546],
                          [-114.958, 31.097],
                          [-115.883, 30.773],
                          [-115.385, 30.072],
                          [-101.94, 23.832],
                          [-87.839, 17.846],
                          [-76.1, 13.067],
                          [-75.993, 12.941],
                          [-76.307, 12.827],
                          [-77.582, 12.214],
                          [-76.485, 11.156],
                          [-58.317, 3.595],
                          [-52.281, -0.529],
                          [-49.285, -1.72],
                          [-46.316, -3.13],
                          [-44.958, -3.11],
                          [-45.504, -2.123],
                          [-48.373, -0.761],
                          [-54.235, 2.039],
                          [-54.404, 2.349],
                          [-53.91, 2.321],
                          [-34.484, -3.263],
                          [-23.649, -6.871],
                          [-15.153, -9.316],
                          [-0.016, -14.076],
                          [15.177, -18.726],
                          [29.416, -23.048],
                          [53.848, -30.129],
                          [78.801, -36.739],
                          [112.077, -44.444],
                          [114.767, -45.291],
                          [115.899, -44.83],
                          [115.084, -44.1],
                          [96.864, -39.817],
                          [77.55, -35.29],
                          [63.105, -31.627],
                          [42.859, -25.792],
                          [26.634, -21.095],
                          [5.972, -14.778],
                          [-16.156, -7.902],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 89,
                    s: [
                      {
                        i: [
                          [5.141, -2.052],
                          [-5.038, 2.087],
                          [-7.547, 2.054],
                          [-0.364, -0.604],
                          [0.365, -0.348],
                          [3.933, -3.79],
                          [1.518, -1.567],
                          [0.052, -0.108],
                          [-0.141, -0.014],
                          [-0.489, -0.034],
                          [-0.292, -0.016],
                          [0.124, 0.207],
                          [-0.059, 0.03],
                          [-7.72, 2.179],
                          [-4.79, -0.111],
                          [-1.287, -1.017],
                          [-1.276, 0.299],
                          [-6.409, 1.07],
                          [-1.191, 0.065],
                          [-0.118, -0.79],
                          [0.65, -0.34],
                          [3.88, -1.765],
                          [3.585, -0.743],
                          [0.715, 2.17],
                          [1.647, -0.511],
                          [3.084, -0.857],
                          [4.936, -1.353],
                          [5.602, -1.171],
                          [5.314, -2.831],
                          [4.388, -2.193],
                          [6.578, -3.242],
                          [4.589, -2.284],
                          [3.89, -1.796],
                          [3.321, -0.643],
                          [1.045, 1.533],
                          [-0.873, 1.217],
                          [-4.83, 4.228],
                          [-1.026, 0.897],
                          [-0.035, 0.078],
                          [0.198, -0.048],
                          [3.817, -0.973],
                          [4.539, -1.18],
                          [2.105, -0.514],
                          [0.153, 0.43],
                          [-0.311, 0.145],
                          [-4.521, 2.007],
                          [-4.715, 1.964],
                          [-3.914, 1.593],
                          [-0.036, 0.043],
                          [0.095, 0.053],
                          [0.098, 0.661],
                          [-0.482, 0.254],
                          [-5.998, 2.626],
                          [-2.05, 1.334],
                          [-5.056, 2.596],
                          [-1.511, 0.818],
                          [-0.412, -0.54],
                          [0.346, -0.204],
                          [1.831, -0.896],
                          [1.431, -1.04],
                          [0.055, -0.106],
                          [-0.151, 0.046],
                          [-6.301, 2.362],
                          [-3.61, 1.184],
                          [-2.819, 0.852],
                          [-5.052, 1.57],
                          [-5.066, 1.545],
                          [-4.761, 1.398],
                          [-8.145, 2.354],
                          [-8.34, 2.136],
                          [-11.107, 2.514],
                          [-0.905, 0.257],
                          [-0.12, -0.456],
                          [0.402, -0.095],
                          [6.074, -1.423],
                          [6.426, -1.548],
                          [4.782, -1.322],
                          [6.747, -1.951],
                          [5.392, -1.613],
                          [6.88, -2.124],
                          [7.382, -2.273],
                        ],
                        o: [
                          [5.47, -0.253],
                          [7.148, -2.96],
                          [0.51, -0.139],
                          [0.375, 0.622],
                          [-3.953, 3.772],
                          [-1.575, 1.518],
                          [-0.082, 0.084],
                          [0.137, 0.045],
                          [0.487, 0.049],
                          [0.292, 0.021],
                          [-0.144, -0.198],
                          [-0.024, -0.04],
                          [7.077, -3.465],
                          [4.48, -1.264],
                          [1.487, 0.035],
                          [1.103, 0.873],
                          [6.299, -1.479],
                          [1.177, -0.196],
                          [0.562, -0.031],
                          [0.117, 0.788],
                          [-3.764, 1.973],
                          [-3.245, 1.477],
                          [-2.841, 0.589],
                          [-0.484, -1.468],
                          [-3.049, 0.945],
                          [-4.931, 1.369],
                          [-5.514, 1.511],
                          [-6.134, 1.282],
                          [-4.32, 2.301],
                          [-6.557, 3.278],
                          [-4.6, 2.266],
                          [-3.832, 1.908],
                          [-2.974, 1.373],
                          [-1.836, 0.356],
                          [-1.04, -1.526],
                          [3.689, -5.145],
                          [1.025, -0.898],
                          [0.061, -0.053],
                          [-0.212, -0.004],
                          [-3.832, 0.922],
                          [-4.545, 1.159],
                          [-2.095, 0.545],
                          [-0.389, 0.095],
                          [-0.141, -0.393],
                          [4.479, -2.086],
                          [4.661, -2.069],
                          [3.898, -1.623],
                          [0.047, -0.019],
                          [-0.105, -0.037],
                          [-0.416, -0.231],
                          [-0.104, -0.695],
                          [5.789, -3.066],
                          [2.221, -0.973],
                          [3.507, -2.28],
                          [3.595, -1.846],
                          [0.406, -0.22],
                          [0.446, 0.585],
                          [-2.035, 1.2],
                          [-2.154, 1.053],
                          [-0.09, 0.064],
                          [0.165, -0.007],
                          [6.444, -1.954],
                          [3.56, -1.334],
                          [2.763, -0.906],
                          [5.066, -1.53],
                          [5.056, -1.572],
                          [4.744, -1.447],
                          [8.134, -2.388],
                          [8.26, -2.388],
                          [11.027, -2.822],
                          [0.916, -0.207],
                          [0.485, -0.137],
                          [0.124, 0.475],
                          [-6.071, 1.435],
                          [-6.439, 1.508],
                          [-4.832, 1.164],
                          [-6.772, 1.872],
                          [-5.409, 1.564],
                          [-6.902, 2.066],
                          [-7.38, 2.279],
                          [-5.216, 1.608],
                        ],
                        v: [
                          [-31.626, -2.401],
                          [-15.957, -5.354],
                          [6.094, -12.955],
                          [7.613, -12.796],
                          [6.93, -11.519],
                          [-6.031, -1.383],
                          [-10.607, 3.301],
                          [-10.767, 3.622],
                          [-10.356, 3.753],
                          [-8.892, 3.866],
                          [-8.016, 3.915],
                          [-8.438, 3.317],
                          [-8.337, 3.115],
                          [13.52, -6.033],
                          [27.215, -8.717],
                          [31.342, -7.387],
                          [35.182, -7.891],
                          [54.113, -12.192],
                          [57.666, -12.665],
                          [59.313, -12.182],
                          [57.869, -10.655],
                          [46.508, -4.853],
                          [36.574, -0.778],
                          [31.615, -4.044],
                          [28.82, -5.389],
                          [19.549, -2.88],
                          [4.776, 1.28],
                          [-11.816, 5.648],
                          [-28.503, 12.588],
                          [-41.655, 19.186],
                          [-61.333, 29.009],
                          [-75.201, 35.692],
                          [-86.599, 41.577],
                          [-95.82, 45.169],
                          [-100.667, 43.898],
                          [-99.39, 39.764],
                          [-86.117, 26.093],
                          [-83.027, 23.412],
                          [-82.92, 23.182],
                          [-83.556, 23.177],
                          [-95.043, 25.973],
                          [-108.649, 29.546],
                          [-114.958, 31.097],
                          [-115.883, 30.773],
                          [-115.385, 30.072],
                          [-101.94, 23.832],
                          [-87.839, 17.846],
                          [-76.1, 13.067],
                          [-75.993, 12.941],
                          [-76.307, 12.827],
                          [-77.582, 12.214],
                          [-76.485, 11.156],
                          [-58.317, 3.595],
                          [-52.281, -0.529],
                          [-12.285, -23.595],
                          [3.122, -31.442],
                          [4.479, -31.423],
                          [3.934, -30.435],
                          [-8.498, -24.261],
                          [-54.235, 2.039],
                          [-54.404, 2.349],
                          [-53.91, 2.321],
                          [-34.484, -3.263],
                          [-23.649, -6.871],
                          [-15.153, -9.316],
                          [-0.016, -14.076],
                          [15.177, -18.726],
                          [29.416, -23.048],
                          [53.848, -30.129],
                          [78.801, -36.739],
                          [112.077, -44.444],
                          [114.767, -45.291],
                          [115.899, -44.83],
                          [115.084, -44.1],
                          [96.864, -39.817],
                          [77.55, -35.29],
                          [63.105, -31.627],
                          [42.859, -25.792],
                          [26.634, -21.095],
                          [5.972, -14.778],
                          [-16.156, -7.902],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.053, -0.283],
                    [0.195, -0.047],
                    [6.786, -1.713],
                    [1.498, -1.276],
                    [3.905, -3.693],
                    [1.66, -2.938],
                    [-0.7, -0.507],
                    [-1.857, 0.608],
                    [-3.092, 1.501],
                    [-4.731, 2.261],
                    [-8.062, 4.018],
                    [-7.719, 3.921],
                    [-0.059, 0.106],
                    [0.021, -0.026],
                    [0.687, -0.163],
                    [7.508, -1.741],
                  ],
                  o: [
                    [-0.833, 0.17],
                    [-6.805, 1.65],
                    [-1.923, 0.486],
                    [-4.108, 3.502],
                    [-2.491, 2.356],
                    [-0.3, 0.531],
                    [1.218, 0.883],
                    [3.344, -1.095],
                    [4.704, -2.284],
                    [8.136, -3.889],
                    [7.753, -3.864],
                    [0.094, -0.048],
                    [-0.124, -0.045],
                    [-0.527, 0.649],
                    [-7.499, 1.773],
                    [-1.699, 0.393],
                  ],
                  v: [
                    [-53.61, 15.985],
                    [-54.835, 16.236],
                    [-75.241, 21.212],
                    [-80.906, 22.972],
                    [-92.922, 33.78],
                    [-99.29, 41.672],
                    [-99.697, 43.418],
                    [-95.395, 44.171],
                    [-85.812, 40.132],
                    [-71.855, 32.995],
                    [-47.576, 21.097],
                    [-24.403, 9.361],
                    [-24.221, 9.052],
                    [-24.583, 8.927],
                    [-26.664, 9.602],
                    [-49.192, 14.807],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 2,
              ty: "sh",
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.896, 0],
                    [2.712, -3.145],
                    [0.331, -0.437],
                    [-0.413, -0.134],
                    [-0.232, -0.181],
                    [0.375, -0.025],
                    [0.509, 0.753],
                    [-0.212, 0.259],
                    [-0.685, 0.706],
                    [-0.131, 0.163],
                    [0.189, -0.067],
                    [5.009, -1.867],
                    [3.503, -1.442],
                    [1.854, -1.244],
                    [2.813, -2.317],
                    [0.123, -0.206],
                    [-0.27, 0.064],
                    [-4.636, 1.075],
                    [-5.689, 1.342],
                    [-5.73, 1.288],
                    [-3.829, 3.046],
                    [2.72, 1.424],
                    [1.901, -0.275],
                    [3.107, -0.962],
                    [2.353, 0.147],
                    [0.154, 0.072],
                    [-0.155, 0.043],
                    [-3.03, 1.163],
                    [-0.493, 0.235],
                  ],
                  o: [
                    [-3.361, 0.388],
                    [-0.359, 0.417],
                    [0.44, 0.066],
                    [0.268, 0.087],
                    [-0.366, 0.137],
                    [-0.797, 0.053],
                    [-0.166, -0.246],
                    [0.617, -0.752],
                    [0.146, -0.15],
                    [-0.221, -0.01],
                    [-5.038, 1.802],
                    [-3.558, 1.326],
                    [-2.148, 0.885],
                    [-3.078, 2.066],
                    [-0.181, 0.149],
                    [0.292, 0.013],
                    [4.63, -1.096],
                    [5.695, -1.321],
                    [5.714, -1.349],
                    [4.749, -1.068],
                    [2.439, -1.939],
                    [-1.382, -0.724],
                    [-3.361, 0.485],
                    [-2.052, 0.635],
                    [-0.158, -0.01],
                    [0.122, -0.139],
                    [3.165, -0.888],
                    [0.511, -0.196],
                    [-0.496, 0],
                  ],
                  v: [
                    [-27.566, -1.808],
                    [-37.617, 1.509],
                    [-38.614, 2.816],
                    [-37.303, 3.034],
                    [-36.601, 3.558],
                    [-37.701, 3.949],
                    [-39.923, 3.405],
                    [-39.776, 2.336],
                    [-37.735, 0.209],
                    [-37.342, -0.277],
                    [-38.001, -0.297],
                    [-53.096, 5.15],
                    [-63.676, 9.332],
                    [-69.135, 13.21],
                    [-77.571, 20.171],
                    [-77.95, 20.783],
                    [-77.078, 20.812],
                    [-63.196, 17.499],
                    [-46.091, 13.606],
                    [-28.982, 9.445],
                    [-15.81, 4.14],
                    [-15.755, -1.296],
                    [-20.822, -2.157],
                    [-29.96, 1.174],
                    [-36.083, 3.161],
                    [-36.546, 2.953],
                    [-36.173, 2.549],
                    [-27.162, -1.108],
                    [-25.679, -1.808],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 3",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 3,
              ty: "sh",
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.171, -1.022],
                    [-0.032, -0.072],
                    [-2.7, 0.678],
                    [-5.404, 1.37],
                    [-1.381, 1.108],
                    [-3.784, 3.139],
                    [-0.169, 0.157],
                    [0.295, -0.119],
                    [5.909, -2.464],
                    [5.315, -2.332],
                  ],
                  o: [
                    [0.032, 0.072],
                    [2.688, -0.718],
                    [5.407, -1.359],
                    [1.699, -0.431],
                    [3.842, -3.079],
                    [0.179, -0.148],
                    [-0.322, 0.062],
                    [-5.945, 2.392],
                    [-5.364, 2.236],
                    [-2.199, 0.967],
                  ],
                  v: [
                    [-109.717, 28.58],
                    [-109.623, 28.796],
                    [-101.557, 26.647],
                    [-85.321, 22.621],
                    [-80.314, 21.078],
                    [-68.938, 11.685],
                    [-68.429, 11.215],
                    [-69.392, 11.408],
                    [-87.208, 18.618],
                    [-103.202, 25.523],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 4",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 4,
              ty: "sh",
              ix: 5,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.134, 0.097],
                    [0.689, -0.202],
                    [7.246, -2.672],
                    [0.116, -0.079],
                    [-0.135, -0.003],
                    [-1.201, -0.519],
                    [1.334, -1.342],
                    [1.264, -1.113],
                    [0.109, -0.123],
                    [-0.155, 0.072],
                    [-1.408, 0.13],
                    [0.2, 0.51],
                    [-0.359, 0.405],
                    [-1.276, 1.248],
                    [-4.499, 2.919],
                    [-0.271, 0.364],
                  ],
                  o: [
                    [-0.703, 0.247],
                    [-7.451, 2.181],
                    [-0.13, 0.048],
                    [0.133, 0.081],
                    [1.396, 0.029],
                    [2.238, 0.969],
                    [-1.177, 1.184],
                    [-0.123, 0.109],
                    [0.19, -0.009],
                    [1.265, -0.587],
                    [0.683, -0.063],
                    [-0.223, -0.571],
                    [1.167, -1.315],
                    [3.8, -3.715],
                    [0.373, -0.242],
                    [0.006, -0.007],
                  ],
                  v: [
                    [5.072, -11.661],
                    [3.015, -10.945],
                    [-18.868, -3.279],
                    [-19.217, -3.044],
                    [-18.819, -2.81],
                    [-15.047, -1.632],
                    [-13.258, 2.858],
                    [-17.04, 6.202],
                    [-17.369, 6.57],
                    [-16.804, 6.535],
                    [-12.811, 5.351],
                    [-12.145, 4.542],
                    [-11.781, 3.126],
                    [-8.209, -0.769],
                    [4.521, -10.377],
                    [5.377, -11.437],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 5",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 5,
              ty: "sh",
              ix: 6,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.274, 0.031],
                    [-1.903, 0.873],
                    [-5.038, 2.809],
                    [0.053, 0.121],
                    [0.271, -0.036],
                    [0.916, -0.201],
                    [0.947, -0.22],
                    [5.534, -1.513],
                    [-1.75, -3.063],
                  ],
                  o: [
                    [1.819, -0.248],
                    [5.276, -2.419],
                    [0.161, -0.09],
                    [-0.035, -0.207],
                    [-0.72, 0.153],
                    [-1.085, 0.2],
                    [-5.602, 1.301],
                    [-0.994, 0.272],
                    [0.655, 1.149],
                  ],
                  v: [
                    [35.889, -1.813],
                    [41.516, -3.648],
                    [57.003, -11.455],
                    [57.415, -11.831],
                    [56.65, -11.899],
                    [54.844, -11.531],
                    [52.077, -10.975],
                    [35.312, -6.936],
                    [32.666, -4.487],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 6",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 6,
              ty: "sh",
              ix: 7,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.103, 0.187],
                    [5.295, -1.697],
                    [5.916, -2.558],
                    [0.074, -0.09],
                    [-0.169, 0.048],
                    [-3.644, 1.021],
                    [-4.883, 1.342],
                    [-2.809, 0.816],
                    [0.116, 0.656],
                  ],
                  o: [
                    [-5.696, 0.178],
                    [-6.188, 1.982],
                    [-0.098, 0.042],
                    [0.187, 0.007],
                    [3.641, -1.029],
                    [4.875, -1.367],
                    [2.823, -0.776],
                    [0.985, -0.287],
                    [-0.191, -1.082],
                  ],
                  v: [
                    [27.005, -7.708],
                    [10.709, -4.049],
                    [-7.146, 3.404],
                    [-7.371, 3.666],
                    [-6.812, 3.678],
                    [4.106, 0.574],
                    [18.744, -3.485],
                    [27.222, -5.786],
                    [29.972, -6.779],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 7",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 7,
              ty: "sh",
              ix: 8,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.093, 0.493],
                    [0.112, -0.044],
                    [2.869, -1.153],
                    [0.081, -0.092],
                    [-0.115, 0.041],
                    [-2.259, 0.845],
                  ],
                  o: [
                    [-0.578, -0.052],
                    [-2.877, 1.136],
                    [-0.104, 0.042],
                    [0.131, -0.007],
                    [2.27, -0.817],
                    [0.737, -0.276],
                  ],
                  v: [
                    [-62.114, 6.656],
                    [-62.967, 6.585],
                    [-71.586, 10.02],
                    [-71.831, 10.29],
                    [-71.441, 10.263],
                    [-64.64, 7.789],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 8",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 8,
              ty: "sh",
              ix: 9,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.683, -1.289],
                    [-1.303, 0.499],
                  ],
                  o: [
                    [1.929, -0.738],
                    [-1.442, 0.189],
                  ],
                  v: [
                    [-59.02, 5.527],
                    [-54.485, 3.791],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 9",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 9,
              ty: "sh",
              ix: 10,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.248, -0.13],
                    [-0.417, 0.196],
                    [0.054, -0.019],
                    [0.394, -0.506],
                  ],
                  o: [
                    [0.425, -0.2],
                    [-0.088, -0.065],
                    [-0.518, 0.175],
                    [0.161, 0.083],
                  ],
                  v: [
                    [-74.614, 12.375],
                    [-73.354, 11.783],
                    [-73.612, 11.599],
                    [-75.184, 12.078],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 10",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 11,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}
export default potpisLottie
