const animation1152 = {
  v: "5.6.8",
  fr: 30,
  ip: 0,
  op: 92,
  w: 4,
  h: 130,
  nm: "Vertikalna linija za razglednice lottie 130px",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "linija",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.997], y: [1] },
                o: { x: [0.167], y: [0] },
                t: 0,
                s: [2.045],
              },
              { t: 91, s: [2.045] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.134], y: [0.985] },
                o: { x: [0.027], y: [0.267] },
                t: 0,
                s: [-136],
              },
              { t: 91, s: [-1] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [0.045, -66, 0], ix: 1 },
        s: { a: 0, k: [50, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "rc",
              d: 1,
              s: { a: 0, k: [3.09, 132.701], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: "Rectangle Path 1",
              mn: "ADBE Vector Shape - Rect",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.113724996529, 0.113724996529, 0.113724996529, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0.045, 0.35], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Rectangle 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 92,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}

export default animation1152
