import img_0 from "../../animations/hero/images/img_0.jpg"
import img_1 from "../../animations/hero/images/img_1.jpg"
import img_2 from "../../animations/hero/images/img_2.jpg"

const animationHero = {
  v: "5.6.8",
  fr: 30,
  ip: 0,
  op: 522,
  w: 1152,
  h: 544,
  nm: "hero video 1152px",
  ddd: 0,
  assets: [
    { id: "image_0", w: 1155, h: 544, u: "", p: img_0, e: 0 },
    { id: "image_1", w: 1155, h: 544, u: "", p: img_1, e: 0 },
    { id: "image_2", w: 1155, h: 544, u: "", p: img_2, e: 0 },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: "dalmacija bacve 2.png",
      cl: "png",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 308,
              s: [0],
            },
            { t: 350, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 308,
              s: [576, 272, 0],
              to: [-11.667, -2.833, 0],
              ti: [11.667, 2.833, 0],
            },
            { t: 515, s: [506, 255, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [577.5, 272, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 308,
              s: [100, 100, 100],
            },
            { t: 515, s: [118, 118, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 308,
      op: 522,
      st: 316,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "1983.png",
      cl: "png",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 174,
              s: [0],
            },
            { t: 211, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 174,
              s: [576, 272, 0],
              to: [-11.358, 0, 0],
              ti: [13.112, 0, 0],
            },
            { t: 351, s: [506.998, 318, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [577.5, 272, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 174,
              s: [100, 100, 100],
            },
            { t: 352, s: [118, 118, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 174,
      op: 353,
      st: 174,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "1982.png",
      cl: "png",
      refId: "image_2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [576, 272, 0],
              to: [-11.667, -7, 0],
              ti: [11.667, 7, 0],
            },
            { t: 213, s: [506, 230, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [577.5, 272, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [100, 100, 100],
            },
            { t: 214, s: [118, 118, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 214,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}

export default animationHero
